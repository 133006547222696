import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
//Modulo que necesita PrimeNG para cargar animaciones
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './env/app/home/home.module';
import { InterceptorService } from './env/app/services/interceptor.service';
import { CargarScriptService } from './env/app/services/template/cargar-script.service';

//Cambia el locale de la app para que el idioma por defecto sea el español
import localeEs from "@angular/common/locales/es-CL";
import { registerLocaleData } from "@angular/common";
import { LOCALE_ID } from '@angular/core';
registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule, //se usa para consultar apis
    
    HomeModule, //los estilos cargan bien, cuando cargo el módulo aquí

    
    
  
    
  ],
  providers:[
    { provide: LOCALE_ID, useValue: 'es-CL' },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:InterceptorService,
      multi:true
    
    },
    CargarScriptService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { AfterContentChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItem } from 'primeng/api';

import { App } from '../interfaces/app.interface';
import { Rol } from '../interfaces/rol.interface';

import { AuthService } from '../services/auth.service';
import { CargarScriptService } from '../services/template/cargar-script.service';
import { AppService } from '../services/template/app.service';
import { EnvAppService } from './services/env-app.service';
import { FuncionesUnify } from '../../components/funciones-unify';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles:[`
    li{
      cursor:pointer;
    }
    
  `
  ]
})
export class HomeComponent implements OnInit, AfterViewInit,AfterContentChecked{
  
  rol!:Rol
  apps : App[] = []
  appNivel1:App|undefined;
  appNivel2:App|undefined;
  appNivel3:App|undefined;
  appNivel4:App|undefined;
  
  eventosCargados:boolean=false;

  items: MenuItem[]= [];

  constructor(private router:Router,
              private session:AuthService,
              private service:EnvAppService,
              private script:CargarScriptService,
              private appService:AppService,
              ) 
{

            
}
  ngAfterContentChecked(): void {
    //console.log(document.getElementsByClassName('.am-left-sidebar'))

    //if(document.getElementsByClassName(".parent").length>0){
      this.script.cargar([
        "/js/active-sidebarleft.js",
      ]);
    //}

  }

  ngAfterViewInit(): void {

    //console.log(document.getElementsByClassName(".parent").length)

    this.script.cargar([
      //"/lib/jquery/jquery.min.js",
      "/lib/perfect-scrollbar/js/perfect-scrollbar.min.js",
      "/lib/bootstrap/dist/js/bootstrap.bundle.min.js",
      //"/js/app.js",
      //"/lib/jquery-flot/jquery.flot.js",
      //"/lib/jquery-flot/jquery.flot.pie.js",
      //"/lib/jquery-flot/jquery.flot.time.js",
      //"/lib/jquery-flot/jquery.flot.resize.js",
      //"/lib/jquery-flot/plugins/jquery.flot.orderBars.js",
      //"/lib/jquery-flot/plugins/curvedLines.js",
      //"/lib/jquery-flot/plugins/jquery.flot.tooltip.js",
      //"/lib/jquery.sparkline/jquery.sparkline.min.js",
      //"/lib/jquery-ui/jquery-ui.min.js",
      //"/lib/jquery.vectormap/jquery-jvectormap-1.2.2.min.js",
      //"/lib/jquery.vectormap/maps/jquery-jvectormap-us-merc-en.js",
      //"/lib/jquery.vectormap/maps/jquery-jvectormap-world-mill-en.js",
      //"/lib/jquery.vectormap/maps/jquery-jvectormap-uk-mill-en.js",
      //"/lib/jquery.vectormap/maps/jquery-jvectormap-fr-merc-en.js",
      //"/lib/jquery.vectormap/maps/jquery-jvectormap-us-il-chicago-mill-en.js",
      //"/lib/jquery.vectormap/maps/jquery-jvectormap-au-mill-en.js",
      //"/lib/jquery.vectormap/maps/jquery-jvectormap-in-mill-en.js",
      //"/lib/jquery.vectormap/maps/jquery-jvectormap-map.js",
      //"/lib/jquery.vectormap/maps/jquery-jvectormap-ca-lcc-en.js",
      //"/lib/countup/countUp.min.js",
      //"/lib/chartjs/Chart.min.js",
      "/js/app-dashboard.js",
      "/js/active.js",
      
    ]);
    


  }

  ngOnInit(): void {
    //if (!this.rol)this.rol=this.session.getRolActual()   
  }

  llenaBreadcrumb(){
    this.items = [];
    if(!this.appNivel1)return;
    
    if(typeof this.appNivel1.nombre === 'undefined'){
      this.appNivel1.nombre = 'Inicio';
    }
    
    var result = FuncionesUnify.primeraMayuscula(this.appNivel1.nombre);
    this.items.push({label: result});
    
    if(!this.appNivel2)return;
    var result = FuncionesUnify.primeraMayuscula(this.appNivel2.nombre);
    this.items.push({label: result});

  }

  getClass(i:number,app:App){
    let pathApp = app.path+""
    let urlActual = this.router.url+""
    pathApp = (pathApp+"").trim().substring(-1,1)=="/"?(pathApp+"").trim().substring(0,(pathApp+"").trim().length-1):(pathApp+"").trim()
    urlActual = (urlActual+"").trim().substring(-1,1)=="/"?(urlActual+"").trim().substring(0,(urlActual+"").trim().length-1):(urlActual+"").trim()

    if ( urlActual.split("/").length==3 && i==0){
      
      return 'nav-link active'
    }

    //console.log(urlActual+"  ---"+pathApp)

    if( (this.router.url+"").includes(pathApp) ){
 
      return 'nav-link active'
    }

    return 'nav-link'
  }

  cargarAppsByRol(rol:Rol){
    this.rol=rol
    this.apps=[] //esto es para que carge el html


    //console.log("user "+this.session.auth?.token!)
    if( !this.rol){
      return
    }
    this.service.getApp(this.rol.token,this.session.auth?.token!).subscribe(
      resp=>{
        this.apps=resp.apps;
        //this.session.setAppsUser(resp.apps,this.rol)
        for (let i = 0; i < this.apps.length; i++) {

          if(this.apps[i].path ==  "/"+this.router.url.split("/")[1]+"/" ){
            
            this.appNivel1 = this.apps[i];

         
            for (let j = 0; j < this.appNivel1.hijos.length; j++) {
              if(this.appNivel1.hijos[j].path ==  "/"+this.router.url.split("/")[1]+"/"+this.router.url.split("/")[2]+"/" ){
                
                this.appNivel2 = this.appNivel1.hijos[j];
                
                for (let k = 0; k < this.appNivel2.hijos.length; k++) {


                  if(this.appNivel2.hijos[k].path ==  "/"+this.router.url.split("/")[1]+"/"+this.router.url.split("/")[2]+"/"+this.router.url.split("/")[3]+"/" ){
                    
                    this.appNivel3 = this.appNivel2.hijos[k];
                    for (let k = 0; k < this.appNivel3.hijos.length; k++) {
    
                      if(this.appNivel3.hijos[k].path ==  "/"+this.router.url.split("/")[1]+"/"+this.router.url.split("/")[2]+"/"+this.router.url.split("/")[3]+"/"+this.router.url.split("/")[4]+"/" ){
                        
                        this.appNivel4 = this.appNivel3.hijos[k];
                        //console.log(this.appNivel3)
                        break
                      }
                    }
                    break
                  }
                }
                break
              }
            }

            this.llenaBreadcrumb()
            break
          }
          
        }
      }
    )
  }

  asignApp(app:App[]){
    //console.log(app)
    this.appNivel1 = app[0];
    this.appNivel2 = app[1];

    //this.llenaBreadcrumb();
  }

  getApps():App[]
  {

    if(!this.appNivel2 || this.appNivel2.hijos.length==0){
      return []
    }

    return this.appNivel2.hijos
  }

  getUrl(app:App){
    return app.path
  }

  setDataToggle(app:App){
    return app.hijos.length>0?"dropdown":"";
  }


  isRouterLinkActive(app:App){

    //console.log(this.router.url)
    //console.log(app.path)
    //si es accesible, entonces puede activarse cuando la url es igual (queda marcado con estilo active)
    if(app.accesible){
      return 'active';
    }

    if((this.router.url+"").includes(app.path+"")){
      //console.log("llego")
      return 'active'
    }

    return ''
  }
  


}
